<template>
    <div>
        <div class="container-fluid form-inline header_div">
            <a href="/" class="logo_link" no-prefetch><img src="/vstatic/images/logo.png" class="logo"/></a>
            <span class="button_containers">
                <!--
                <GuidedTourBtn/>
                -->
                <button class="btn btn-primary trial-btn prevent_vertical_expansion" 
                    @click="signin"
                    >Sign in</button>
                
                <button class="btn btn-outline-primary trial-btn prevent_vertical_expansion" v-if="false"
                    @click="signup"
                    >Sign up</button>
            </span>

        </div>
        <div >
            <slot />

        </div>
    </div>
</template>
<script setup>
const signup = (event) => {
  window.location.href = '/signup';
}

const signin = (event) => {
    window.location.href = '/vlogin';
}

</script>

<style scoped>
.header_div {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding: 6px;
  padding-left: 250px;
  text-align: right;
  position: fixed;
  z-index: 1001;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.header_div .logo {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
  width: 170px;
}
.header_div button,
.header_div a {
  margin: 0px 10px;
}
.button_containers {
    float: right;
}
</style>
